import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CommerceBillingCycleClient,
  SubscriptionStateEnum
} from '@monetization/hpaip-notification-rules-react'
import PendingCard from './PendingCard'
import ChargesCard from './shared/ChargesCard'
import { getCancelOption, getCancelledCost } from './cancelAmount'
import { fetchCostValue, calculateInvoiceData } from './subscriptionDataUtil'
import checkRemorsePendingTrailData from './pendingChangesDataUtil'
import CancellingCard from './CancellingCard'
import CancelledCard from './CancelledCard'
import { useCardState, CommonProps } from 'src/utils/common-util'
import { EstimatedChargesHeightCard } from '../styles'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import APIErrorCard from './shared/APIErrorCard'

interface PrinterProps {
  loadingPrinter
  printerData: any
  returnDetails: any
  printerOptionDetails?: any
  refresh: boolean
  resetRefreshCallback: any
  commonProps: CommonProps
}
const MonthlyCharges: React.FunctionComponent<PrinterProps> = ({
  printerData,
  printerOptionDetails,
  returnDetails,
  loadingPrinter,
  refresh,
  resetRefreshCallback,
  commonProps
}) => {
  const { authProvider, stack, t } = commonProps
  const [estimatedChargeData, setEstimatedChargeData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [costValue, setCostValue] = useState<any>(null)
  const [invoiceAmountData, setInvoiceAmount] = useState<any>()
  const [invoiceData, setInvoiceData] = useState<any>()

  const { disableCardLink } = useCardState(
    loadingPrinter,
    printerData?.printer,
    printerData?.replacement
  )

  const subscriptionClient = React.useMemo(
    () => new CommerceBillingCycleClient(authProvider, stack),
    [authProvider, stack]
  )

  const { isCancelledInRemorse, cancelledData } = checkRemorsePendingTrailData(
    printerData?.pendingData
  )

  const handleRefresh = useCallback(() => {
    // Call the refreshCallback function to set refresh to false
    resetRefreshCallback()
  }, [resetRefreshCallback])

  useEffect(() => {
    const fetchData = async () => {
      handleRefresh()
    }
    fetchData()
  }, [handleRefresh])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      try {
        const subscriptionId =
          printerData?.printer?.commerce?.value?.subscriptionId
        const subscriptionResponse =
          await subscriptionClient.getSubscriptionData(subscriptionId)
        const customerId = subscriptionResponse?.customerId

        const invoiceResponse =
          await subscriptionClient.getInvoiceData(customerId)
        const data = invoiceResponse?.filter(
          (item) => item.resourceType === 'Purchase_Cancellation'
        )
        setInvoiceAmount(data)

        let overdueChargesresponse = null
        try {
          overdueChargesresponse =
            await subscriptionClient.getOverdueCharges(customerId)
        } catch (error) {
          console.error('Error fetching data:', error)
        }

        if (printerData?.printer?.state !== SubscriptionStateEnum.CANCELED) {
          const estimatedChargeResponse =
            await subscriptionClient.getEstimatedchargeStatus(customerId)

          const mergedResponse = estimatedChargeResponse.map((item) => ({
            ...item,
            overdueCharges: overdueChargesresponse?.grossOverdueAmount || 0
          }))

          setEstimatedChargeData(mergedResponse)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
        if (error instanceof Error) {
          setError(true)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchData()
  }, [printerData?.printer, refresh, subscriptionClient])

  useEffect(() => {
    const calculateInvoice = () => {
      if (estimatedChargeData) {
        const calculatedData = calculateInvoiceData(estimatedChargeData)
        return calculatedData
      }
      return null
    }

    setInvoiceData(calculateInvoice())
  }, [estimatedChargeData])

  const returnStatusArray = useMemo(
    () => ['new', 'initiated', 'processing', 'itemReceived'],
    []
  )
  const returnErrorStatus = useMemo(
    () => ['timeout', 'completeTimeout', 'completeUnsuccessful'],
    []
  )

  useEffect(() => {
    if (
      (isCancelledInRemorse &&
        returnStatusArray.includes(returnDetails?.status)) ||
      returnErrorStatus.includes(returnDetails?.status)
    ) {
      fetchCostValue(
        cancelledData,
        printerOptionDetails,
        getCancelOption,
        getCancelledCost,
        setCostValue
      )
    }
  }, [
    cancelledData,
    printerOptionDetails,
    isCancelledInRemorse,
    returnDetails,
    returnStatusArray,
    returnErrorStatus
  ])

  if (isLoading) {
    return (
      <EstimatedChargesHeightCard>
        <LoadingHandler
          loading={true}
          $position="absolute"
          loadingText={t('dashboard.paas.loading', 'Loading...')}
        ></LoadingHandler>
      </EstimatedChargesHeightCard>
    )
  }
  switch (printerData?.printer?.state) {
    case SubscriptionStateEnum.PENDING:
      return (
        <PendingCard
          t={t}
          isReplacement={printerData?.replacement}
          estimatedChargeData={invoiceData}
          error={error}
          disableCardLink={disableCardLink}
        />
      )
    case SubscriptionStateEnum.SUBSCRIBED:
      return (
        <ChargesCard
          estimatedChargeData={invoiceData}
          t={t}
          error={error}
          disableCardLink={disableCardLink}
        />
      )
    case SubscriptionStateEnum.SUSPENDED:
      return (
        <ChargesCard
          estimatedChargeData={invoiceData}
          t={t}
          error={error}
          disableCardLink={disableCardLink}
        />
      )
    case SubscriptionStateEnum.CANCELED:
      return (
        <CancelledCard
          t={t}
          returnDetails={returnDetails}
          invoiceAmountData={invoiceAmountData}
        />
      )
    case SubscriptionStateEnum.CANCELING:
      return (
        <CancellingCard
          isCancelledInRemorse={isCancelledInRemorse}
          costValue={costValue}
          estimatedChargeData={invoiceData}
          t={t}
          returnDetails={returnDetails}
          error={error}
          disableCardLink={disableCardLink}
        />
      )
    default:
      return <APIErrorCard t={t} />
  }
}

export default MonthlyCharges
